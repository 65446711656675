import React from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import VideoPage from '../components/videopage/VideoPage';

const Videos = () => {
  return (
    <Layout>
      <Seo />
      <VideoPage />
    </Layout>
  );
};

export default Videos;
